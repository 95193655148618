"use client";

import React, { useRef, useState } from "react";

import Map, {
  GeolocateControl,
  MapProvider,
  Marker,
  NavigationControl,
  useMap,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import styles from "./LocationDetails.module.scss";
import H2 from "@/components/H2";
import { TextInput } from "@/components/form/TextInput";
import { TextArea } from "@/components/form/TextArea";
import { Button } from "@/components/actions/Button";
import AnimateIn from "@/components/AnimateIn";
import SelectInput from "@/components/form/Select/Select";
import Form from "./Form";

const mapboxToken = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;

/**
 *
 * LocationDetails
 *
 */
const LocationDetails = ({ data = {}, parent = {}, ...props }) => {
  const [zoom, setZoom] = useState(12);
  const mapRef = useRef(null);

  return (
    <div className="py-32">
      <AnimateIn
        as="section"
        className={`${styles["location-details"]} mx-gutter bg-dark-blue px-gutter lg:rounded py-12 overflow-hidden grid gap-12 relative`}
      >
        <div className="absolute top-[-100px] left-40 z-0">
          <svg
            width="674"
            height="674"
            viewBox="0 0 674 674"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.3" clipPath="url(#clip0_1_649)">
              <path
                d="M366.383 87.3203L366.383 586.067"
                stroke="#C4E1DD"
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M280.922 102.38L451.641 571.041"
                stroke="#C4E1DD"
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M205.765 145.734L526.615 527.789"
                stroke="#C4E1DD"
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M149.983 212.155L582.262 461.528"
                stroke="#C4E1DD"
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M120.304 293.635L611.872 380.241"
                stroke="#C4E1DD"
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M120.304 380.343L611.872 293.737"
                stroke="#C4E1DD"
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M149.983 461.823L582.262 212.45"
                stroke="#C4E1DD"
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M205.765 528.243L526.615 146.188"
                stroke="#C4E1DD"
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M280.922 571.597L451.641 102.925"
                stroke="#C4E1DD"
                strokeWidth="4"
                strokeMiterlimit="10"
                strokeLinecap="round"
              />
              <path
                d="M366.384 404.509C403.867 404.509 434.253 374.147 434.253 336.694C434.253 299.241 403.867 268.879 366.384 268.879C328.9 268.879 298.514 299.241 298.514 336.694C298.514 374.147 328.9 404.509 366.384 404.509Z"
                fill="#C4E1DD"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_649">
                <rect
                  width="496.108"
                  height="503.283"
                  fill="white"
                  transform="translate(107.82 77.1143)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="stack gap-6 relative z-[1]">
          <h2 className="text-white text-[3rem]">
            Have questions? Contact {parent?.title}
          </h2>
          <Form page={parent?.slug} location={parent?.title} />
        </div>
        <div className="rounded h-full w-full overflow-hidden relative z-[1]">
          <Map
            mapboxAccessToken={mapboxToken}
            mapStyle="mapbox://styles/bairdlb/clvekfrja028a01ph1ueu8xsc"
            // dragPan={false}
            scrollZoom={false}
            onZoom={(e) => setZoom(e.viewState.zoom)}
            initialViewState={{
              latitude: data?.locationDetail?.lat || 41.878113,
              longitude: data?.locationDetail?.lon || -87.629799,
              zoom: 12,
            }}
            maxZoom={20}
            minZoom={7}
            ref={mapRef}
            reuseMaps
          >
            <Marker
              longitude={data?.locationDetail?.lon}
              latitude={data?.locationDetail?.lat}
            >
              <button
                type="button"
                className="cursor-pointer flex flex-col gap-1 items-start"
              >
                <img
                  src={parent?.featuredImage?.url}
                  alt={parent?.title}
                  className="rounded-lg object-cover"
                  style={{ width: `${zoom * 20}px` }}
                />
                <div
                  className="text-[1.2rem] font-bold uppercase leading-none text-dark-blue text-left"
                  style={{
                    width: `${zoom * 30}px`,
                    color: "var(--dark-blue) !important",
                  }}
                >
                  {parent?.title}
                </div>
                <div
                  className="text-[1.1rem] font-normal uppercase leading-none text-dark-blue text-left"
                  style={{ width: `${zoom * 30}px` }}
                >
                  {data?.locationAddress}
                </div>
                <div
                  className="text-[1.1rem] font-normal uppercase leading-none text-dark-blue text-left"
                  style={{ width: `${zoom * 30}px` }}
                >
                  {data?.locationCity}, {data?.locationState},{" "}
                  {data?.locationZipCode}
                </div>
                <div
                  className="text-[1.1rem] font-normal uppercase leading-none text-dark-blue text-left"
                  style={{ width: `${zoom * 30}px` }}
                >
                  {data?.locationPhoneNumber}
                </div>
              </button>
            </Marker>
          </Map>
        </div>
      </AnimateIn>
    </div>
  );
};

export default LocationDetails;
